<template>
  <div>
    <custom-snackbar
        ref="snackbar"
    />
    <ActionsListagemSolicitacoesServicosComponent v-model="headers" :filtro="filtro"></ActionsListagemSolicitacoesServicosComponent>
    <FilterListagemSolicitacoesServicosComponent v-model="filtro"></FilterListagemSolicitacoesServicosComponent>
    <ListagemSolicitacoesServicosComponents :filtros="filtro" :headers="headers"></ListagemSolicitacoesServicosComponents>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import FilterListagemSolicitacoesServicosComponent from './components/FilterListagemSolicitacoesServicosComponent.vue'
import ActionsListagemSolicitacoesServicosComponent from './components/ActionsListagemSolicitacoesServicosComponent.vue'
import ListagemSolicitacoesServicosComponents from './components/ListagemSolicitacoesServicosComponents.vue'
export default {
  components: { ListagemSolicitacoesServicosComponents, FilterListagemSolicitacoesServicosComponent, ActionsListagemSolicitacoesServicosComponent },
  data: (instance) => {
    return {
      filtro: {
        empresaId: instance.$route.query.empresaId ? parseInt(instance.$route.query.empresaId) : 0,
        producaoId: instance.$route.query.producaoId ? parseInt(instance.$route.query.producaoId) : 0,
        dataInicial: instance.$route.query.dataInicial ? instance.$route.query.dataInicial : instance.$date.getDateNowIso(),
        dataFinal: instance.$route.query.dataFinal ? instance.$route.query.dataFinal : instance.$date.getDateNowIso(),
        unidadeNegocio: instance.$route.query.unidadeNegocio || 0,
        situacao: instance.$route.query.situacao || 'Z',
        codigoOC: instance.$route.query.codigoOC || '',
        codigoSC: instance.$route.query.codigoSC || '',
        produto: instance.$route.query.produto || '',
        fornecedor: instance.$route.query.fornecedor || '',
        comprador: instance.$route.query.comprador || '',
        observacao: instance.$route.query.observacao || '',
      },
      headers: [],
    }
  },
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Compras' },
      { title: 'Listagem das Solicitações Serviços' },
    ])
  },
}
</script>
