import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mb-5"},[_c(VCardText,{staticClass:"py-0 px-0 px-md-4 py-md-4"},[_c(VDataTable,{ref:"tabelaCompras",attrs:{"dense":"","show-expand":"","single-expand":"","item-key":"row-key","expanded":_vm.expanded,"headers":_vm.headers,"items":_vm.data,"loading":_vm.loading,"no-data-text":_vm.noData,"items-per-page":_vm.$dataTableConfig.getItemsPerPageCustom(),"footer-props":_vm.$dataTableConfig.getFooterProps(),"loading-text":_vm.$dataTableConfig.getLoadText(),"mobile-breakpoint":0},on:{"item-expanded":_vm.expandirSolicitacaoCompra,"click:row":function (item, slot) { return slot.expand(!slot.isExpanded); },"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.Etapa",fn:function(ref){
var item = ref.item;
return [_c('custom-label',{class:_vm.getColor(item),attrs:{"text":item.status}})]}},{key:"item.codigo_os",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(item.codigo_os))])]}},{key:"item.data_emissao_os",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_emissao_os)))])]}},{key:"item.data_aprovacao_nivel_1_os",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_aprovacao_nivel_1_os)))])]}},{key:"item.data_aprovacao_nivel_2_os",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_aprovacao_nivel_2_os)))])]}},{key:"item.data_compra",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_compra)))])]}},{key:"item.data_emissao_nf",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_emissao_nf)))])]}},{key:"item.data_vencimento_nf",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.validDate(item.data_vencimento_nf)))])]}},{key:"item.producao",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(item.producao))])]}},{key:"item.contrantante",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(item.contrantante))])]}},{key:"item.digitador",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(item.digitador))])]}},{key:"item.fornecedor",fn:function(ref){
var item = ref.item;
return [_c('span',{style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(item.fornecedor))])]}},{key:"item.itens_total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(item.itens_total))])]}},{key:"item.valor_total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valor_total, 2)))])]}},{key:"item.valor_bruto",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valor_bruto, 2)))])]}},{key:"item.valor_base_ins",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valor_base_ins, 2)))])]}},{key:"item.valor_ins",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valor_ins, 2)))])]}},{key:"item.valor_int",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valor_int, 2)))])]}},{key:"item.valor_base_irf",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valor_base_irf, 2)))])]}},{key:"item.valor_irf",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valor_irf, 2)))])]}},{key:"item.valor_liquido",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin",style:(item.status_code === 3 ? 'color:red': '')},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valor_liquido, 2)))])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('VisualizaSolicitacaoServicosComponents',{attrs:{"compra":item},on:{"entregue":_vm.getData}})],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }