<template>
  <v-card class="mb-5">
    <v-card-text class="py-0 px-0 px-md-4 py-md-4">
      <v-data-table
            ref="tabelaCompras"
            dense
            show-expand
            single-expand
            item-key="row-key"
            @item-expanded="expandirSolicitacaoCompra"
            @click:row="(item, slot) => slot.expand(!slot.isExpanded)"
            :expanded.sync="expanded"
            :headers="headers"
            :items="data"
            :loading="loading"
            :no-data-text="noData"
            :items-per-page="$dataTableConfig.getItemsPerPageCustom()"
            :footer-props="$dataTableConfig.getFooterProps()"
            :loading-text="$dataTableConfig.getLoadText()"
            :mobile-breakpoint="0"
          >
            <template v-slot:progress>
              <v-progress-linear
                absolute
                indeterminate
                color="green"
              />
            </template>

            <template v-slot:[`item.Etapa`]="{ item }">
              <custom-label
                :class="getColor(item)"
                :text="item.status"
              />
            </template>

            <template v-slot:[`item.codigo_os`]="{ item }">
              <span :style="item.status_code === 3 ? 'color:red': ''">{{ item.codigo_os }}</span>
            </template>

            <template v-slot:[`item.data_emissao_os`]="{ item }">
              <span :style="item.status_code === 3 ? 'color:red': ''">{{ validDate(item.data_emissao_os) }}</span>
            </template>

            <template v-slot:[`item.data_aprovacao_nivel_1_os`]="{ item }">
              <span :style="item.status_code === 3 ? 'color:red': ''">{{ validDate(item.data_aprovacao_nivel_1_os) }}</span>
            </template>

            <template v-slot:[`item.data_aprovacao_nivel_2_os`]="{ item }">
              <span :style="item.status_code === 3 ? 'color:red': ''">{{ validDate(item.data_aprovacao_nivel_2_os) }}</span>
            </template>

            <template v-slot:[`item.data_compra`]="{ item }">
              <span :style="item.status_code === 3 ? 'color:red': ''">{{ validDate(item.data_compra) }}</span>
            </template>

            <template v-slot:[`item.data_emissao_nf`]="{ item }">
              <span :style="item.status_code === 3 ? 'color:red': ''">{{ validDate(item.data_emissao_nf) }}</span>
            </template>

            <template v-slot:[`item.data_vencimento_nf`]="{ item }">
              <span :style="item.status_code === 3 ? 'color:red': ''">{{ validDate(item.data_vencimento_nf) }}</span>
            </template>

            <template v-slot:[`item.producao`]="{ item }">
              <span :style="item.status_code === 3 ? 'color:red': ''">{{ item.producao }}</span>
            </template>
            <template v-slot:[`item.contrantante`]="{ item }">
              <span :style="item.status_code === 3 ? 'color:red': ''">{{ item.contrantante }}</span>
            </template>
            <template v-slot:[`item.digitador`]="{ item }">
              <span :style="item.status_code === 3 ? 'color:red': ''">{{ item.digitador }}</span>
            </template>
            <template v-slot:[`item.fornecedor`]="{ item }">
              <span :style="item.status_code === 3 ? 'color:red': ''">{{ item.fornecedor }}</span>
            </template>

            <template v-slot:[`item.itens_total`]="{ item }">
              <span class="num_margin" :style="item.status_code === 3 ? 'color:red': ''">{{ item.itens_total }}</span>
            </template>

            <template v-slot:[`item.valor_total`]="{ item }">
              <span class="num_margin" :style="item.status_code === 3 ? 'color:red': ''">{{ $stringFormat.formatNumber(item.valor_total, 2) }}</span>
            </template>

            <template v-slot:[`item.valor_bruto`]="{ item }">
              <span class="num_margin" :style="item.status_code === 3 ? 'color:red': ''">{{ $stringFormat.formatNumber(item.valor_bruto, 2) }}</span>
            </template>

            <template v-slot:[`item.valor_base_ins`]="{ item }">
              <span class="num_margin" :style="item.status_code === 3 ? 'color:red': ''">{{ $stringFormat.formatNumber(item.valor_base_ins, 2) }}</span>
            </template>

            <template v-slot:[`item.valor_ins`]="{ item }">
              <span class="num_margin" :style="item.status_code === 3 ? 'color:red': ''">{{ $stringFormat.formatNumber(item.valor_ins, 2) }}</span>
            </template>

            <template v-slot:[`item.valor_int`]="{ item }">
              <span class="num_margin" :style="item.status_code === 3 ? 'color:red': ''">{{ $stringFormat.formatNumber(item.valor_int, 2) }}</span>
            </template>

            <template v-slot:[`item.valor_base_irf`]="{ item }">
              <span class="num_margin" :style="item.status_code === 3 ? 'color:red': ''">{{ $stringFormat.formatNumber(item.valor_base_irf, 2) }}</span>
            </template>

            <template v-slot:[`item.valor_irf`]="{ item }">
              <span class="num_margin" :style="item.status_code === 3 ? 'color:red': ''">{{ $stringFormat.formatNumber(item.valor_irf, 2) }}</span>
            </template>

            <template v-slot:[`item.valor_liquido`]="{ item }">
              <span class="num_margin" :style="item.status_code === 3 ? 'color:red': ''">{{ $stringFormat.formatNumber(item.valor_liquido, 2) }}</span>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <VisualizaSolicitacaoServicosComponents :compra="item" @entregue="getData"></VisualizaSolicitacaoServicosComponents>
              </td>
            </template>
          </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

import ApiService from '@/core/services/api.service'

import VisualizaSolicitacaoServicosComponents from './VisualizaSolicitacaoServicosComponents.vue'

export default {
  components: {
    VisualizaSolicitacaoServicosComponents,
  },
  props: {
    headers: {
      type: Object,
    },
    filtros: {
      type: Object,
    },
  },
  data: () => ({
    // General
    loading: false,
    noData: 'Nenhuma informação encontrada',
    data: [],
    expanded: [],
  }),

  watch: {
    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.getData()
    },
  },

  mounted () {
    this.getData()

    this.$refs.tabelaCompras.$el.querySelector('thead').addEventListener('wheel', (evt) => {
      evt.preventDefault()
      this.$refs.tabelaCompras.$el.querySelector('.v-data-table__wrapper').scrollLeft += evt.deltaY
    })
  },

  methods: {
    getData () {
      if (this.loading) {
        return
      }

      this.loading = true
      this.expanded = []

      ApiService
        .get(
          '/compras/servicos',
          '?' + this.$stringFormat.queryParams(this.filtros),
        )
        .then((res) => {
          this.data = res.data.data.map((item, index) => ({ 'row-key': `${index}`, ...item }))
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    validDate (value) {
      if (value == null) {
        return '-'
      }

      if (value < '1901-01-01') {
        return '-'
      }

      return this.$stringFormat.dateISOToBRL(value)
    },

    getColor (item) {
      return ([
        'bg-executada',
        'bg-validada-1',
        'bg-validada-2',
        'bg-urgente',
      ])[item.status_code]
    },
  },
}
</script>

<style>
.v-btn--height-fix {
  width: 100%;
  height: 40px;
}
.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
.v-btn--height-fix {
  width: 100%;
  height: 40px;
}
.theme--light.v-icon:focus::after {
  opacity: 0 !important;
}
.bg-validada-1 {
  background-color: #AF00AF;
  color: #fff;
}
.bg-executada {
  background-color: #FDD01C;
  color: #000;
}
.bg-validada-2 {
  background-color: #700070;
  color: #fff;
}
.bg-urgente {
  background-color: #E36968;
  color: #fff;
}
.label.label-lg.label-inline {
  width: 100%;
}
.custom-switch,
.custom-switch .v-input__slot,
.custom-switch label {
  padding: 0 !important;
  margin: 0 !important;
}
.custom-switch .v-input--selection-controls__ripple {
  display: none !important;
}
</style>
