<template>
  <v-container>
    <custom-snackbar
        ref="snackbar"
    />
    <v-form ref="form">
      <v-data-table
          item-key="seqipo"
          hide-default-footer
          dense
          :headers="headersItens"
          :items="itens"
          :loading="loadingItens"
          :loading-text="$dataTableConfig.getLoadText()"
          :items-per-page="-1"
          :mobile-breakpoint="0"
      >

        <template v-slot:progress>
          <v-progress-linear
              absolute
              indeterminate
              color="green"
          />
        </template>

        <template v-slot:[`item.valor`]="{ item }">
          <span class="num_margin">{{ $stringFormat.formatNumber(item.valor, 2) }}</span>
        </template>
      </v-data-table>

    </v-form>
    <hr class="my-3"/>

    <h6 v-if="compra.digitador !== '-' && compra.digitador !== null">Digitador: {{ compra.digitador }}</h6>
    <h6 v-if="compra.contratante !== '-' && compra.contratante !== null">Contratante: {{ compra.contratante }}</h6>
    <h6 v-if="compra.fornecedor !== '-' && compra.fornecedor !== null">Fornecedor: {{ compra.fornecedor }}</h6>
    <h6 v-if="compra.observacao !== '-'">Justificativa: {{ compra.observacao }}</h6>
  </v-container>
</template>

<script>

import ApiService from '@/core/services/api.service'

export default {
  props: {
    compra: {
      type: Object,
    },
  },
  data: () => ({
    fornecedor: '-',
    itens: [],
    loadingItens: false,
    showEntregaParcial: false,
    showEntregaTotal: false,
  }),

  computed: {
    disabledVisualizacaoEntrega () {
      return ([9, 10]).includes(this.compra.status_code)
    },
    headersItens () {
      return [
        { text: 'Código Serviço', value: 'servico_id' },
        { text: 'Serviço', value: 'servico' },
        { text: 'Bem Patri.', value: 'bem_patrimonial' },
        { text: 'Valor (R$)', value: 'valor', align: 'right' },
      ]
    },
  },
  mounted () {
    this.init()
  },
  watch: {
    compra () {
      this.init()
    },
  },
  methods: {
    init () {
      this.itens = []
      this.loadingItens = true

      ApiService
        .get('/compras/servicos/' + this.compra.compras_id + '/itens')
        .then((res) => res.data)
        .then((data) => data.data)
        .then((itens) => {
          this.itens = itens
          this.loadingItens = false
          this.fornecedor = itens[0]?.fornecedor
        })
    },
    validarEntregaTotal () {
      if (this.compra.entrega_parcial) {
        this.$refs.snackbar.show('Entrega Total Bloqueada', 'Não é possível realizar uma entrega total para uma ordem de compra com entrega parcial registrada.', 'alert', 5000, false, true)
        return
      }

      this.showEntregaTotal = true
    },
    validarEntregaParcial () {
      if (this.compra.sc === '0') {
        this.$refs.snackbar.show('Entrega Parcial Bloqueada', 'Para compras direta só é possível realizar entrega total.', 'alert', 5000, false, true)
        return
      }

      this.showEntregaParcial = true
    },
    assumirCotacao () {
      ApiService
        .put('/compras/produtos/' + this.compra.compras_id + '/assumir_cotacao')
        .then((res) => res.data)
        .then((data) => {
          console.log(data)
        })
    },
  },
}
</script>
