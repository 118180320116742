<template>
  <div>
    <v-toolbar flat class="d-flex flex-row-reverse pb-5" color="rgba(0,0,0,0)">
      <v-btn
          v-if="$auth.hasPermission('tela.criar.solicitacao.servico')"
          class="mr-3"
          color="primary"
          dark
          @click="addNew"
      >
        <v-icon>
          mdi-plus
        </v-icon>
      </v-btn>
      <v-btn
          color="primary"
          dark
          class="mr-3"
          title="Remover Filtros"
          @click="removeFiltros"
      >
        <v-icon>mdi-filter-remove</v-icon>
      </v-btn>
      <HeaderControl v-model="model" :headers="headersOptions" local-storage-save-name="solicitacoes-servicos"></HeaderControl>
      <Exportar pdf excel :filtro="filtro" url-pdf="/reports/listagem-ordens/relatorio-pdf" relatorioExcel="ListagemOrdemServico"></Exportar>
    </v-toolbar>
  </div>
</template>

<script>
import { getToken } from '@/core/services/storage.service'

export default {
  props: {
    value: {
      type: Object,
    },
    filtro: {
      type: Object,
    },
  },
  data: () => {
    return {
      headers: [],
      headersOptions: [
        { value: 'data-table-expand', align: 'right p-0', class: 'sticky-header custom-table--header' },
        { text: 'Status', value: 'Etapa', class: 'sticky-header custom-table--header', obrigatorio: true },
        { text: 'Produção', value: 'producao', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Código OS', value: 'codigo_os', class: 'sticky-header custom-table--header', obrigatorio: true },
        { text: 'Data OS', value: 'data_emissao_os', class: 'sticky-header custom-table--header', obrigatorio: true },
        { text: 'Data Aprv. Nv. 1', value: 'data_aprovacao_nivel_1_os', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Data Aprv. Nv. 2', value: 'data_aprovacao_nivel_2_os', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Tipo Documento', value: 'tipo_documento', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Numero Documento', value: 'numero_documento', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Data Emissão NF/RPA', value: 'data_emissao_nf', class: 'custom-table--header', ativado: true },
        { text: 'Data Vencimento NF', value: 'data_vencimento_nf', class: 'custom-table--header', ativado: true },
        { text: 'Data Compra', value: 'data_compra', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Itens', value: 'itens_total', align: 'right', class: 'sticky-header custom-table--header', ativado: true },
        { text: 'Valor (R$)', value: 'valor_total', align: 'right', class: 'sticky-header custom-table--header', ativado: true },

        { text: 'Digitador', value: 'digitador', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Contratante', value: 'contratante', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Fornecedor', value: 'fornecedor', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Descrição Serviço RPA', value: 'descricao_servico', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Valor Bruto (R$) RPA', value: 'valor_bruto', align: 'right', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Valor Base INSS (R$) RPA', value: 'valor_base_ins', align: 'right', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Valor INSS (R$) RPA', value: 'valor_ins', align: 'right', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Valor SEST/SENAT (R$) RPA', value: 'valor_int', align: 'right', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Valor Base IRRF (R$) RPA', value: 'valor_base_irf', align: 'right', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Valor IRRF (R$) RPA', value: 'valor_irf', align: 'right', class: 'sticky-header custom-table--header', ativado: false },
        { text: 'Valor Líquido (R$) RPA', value: 'valor_liquido', align: 'right', class: 'sticky-header custom-table--header', ativado: false },
      ],
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    addNew () {
      const url = process.env.VUE_APP_NEW_URL +
          '/login/legacy-app?route=service-requests.create' +
          '&token=' + getToken()

      window.open(url, '_blank')
    },
    removeFiltros () {
      this.$router.push({ query: null })
      window.location.reload()
    },
  },
}
</script>

<style scoped>

</style>
