import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c('custom-snackbar',{ref:"snackbar"}),_c(VForm,{ref:"form"},[_c(VDataTable,{attrs:{"item-key":"seqipo","hide-default-footer":"","dense":"","headers":_vm.headersItens,"items":_vm.itens,"loading":_vm.loadingItens,"loading-text":_vm.$dataTableConfig.getLoadText(),"items-per-page":-1,"mobile-breakpoint":0},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c(VProgressLinear,{attrs:{"absolute":"","indeterminate":"","color":"green"}})]},proxy:true},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"num_margin"},[_vm._v(_vm._s(_vm.$stringFormat.formatNumber(item.valor, 2)))])]}}],null,true)})],1),_c('hr',{staticClass:"my-3"}),(_vm.compra.digitador !== '-' && _vm.compra.digitador !== null)?_c('h6',[_vm._v("Digitador: "+_vm._s(_vm.compra.digitador))]):_vm._e(),(_vm.compra.contratante !== '-' && _vm.compra.contratante !== null)?_c('h6',[_vm._v("Contratante: "+_vm._s(_vm.compra.contratante))]):_vm._e(),(_vm.compra.fornecedor !== '-' && _vm.compra.fornecedor !== null)?_c('h6',[_vm._v("Fornecedor: "+_vm._s(_vm.compra.fornecedor))]):_vm._e(),(_vm.compra.observacao !== '-')?_c('h6',[_vm._v("Justificativa: "+_vm._s(_vm.compra.observacao))]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }