<template>
  <div>
    <FiltroMobile :has-change="change" @search="seachFilter">
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <DatePicker label="Data Inicial" v-model="dataInicial" :disabled="typeof codigoOS === 'string' && codigoOS !== ''"/>
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <DatePicker label="Data Final" v-model="dataFinal" :disabled="typeof codigoOS === 'string' && codigoOS !== ''"/>
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-3">
        <EmpresasSelect v-model="empresaId" @change="change = true" mostra-todos-label></EmpresasSelect>
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-3">
        <ProducoesSelect v-model="producaoId" :empresa="empresaId" :disabled="!empresaId" administrativo @change="change = true" mostra-todos-label></ProducoesSelect>
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-select
            dense
            outlined
            label="Unidade de Negócio"
            item-text="nome"
            item-value="id"
            v-model="unidadeNegocio"
            :items="unidadeNegocioOptionsFilter"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-2">
        <v-select
            dense
            outlined
            label="Situação"
            v-model="situacao"
            :items="situacaoOptionsFilter"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-3">
        <v-text-field
            dense
            outlined
            label="Código Ordem de Serviço"
            v-model="codigoOS"
            @change="change = true"
        />
      </v-col>
      <v-col class="pb-0 col-12 col-md-6 col-lg-3">
        <v-text-field
            dense
            outlined
            label="Serviço"
            v-model="servico"
            @change="change = true"
        />
      </v-col>
      <template v-slot:outros>
        <v-col class="pb-0 col-12">
          <FornecedorSelect v-model="fornecedor" :empresa="empresaId" :producao="producaoId" text-option @change="change = true"/>
        </v-col>
        <v-col class="pb-0 col-12">
          <v-text-field
              dense
              outlined
              label="Contratante"
              v-model="contratante"
              @change="change = true"
          />
        </v-col>
        <v-col class="pb-0 col-12">
          <v-text-field
              dense
              outlined
              label="Digitador"
              v-model="digitador"
              @change="change = true"
          />
        </v-col>
        <v-col class="pb-0 col-12">
          <v-text-field
              dense
              outlined
              label="Justificativa"
              v-model="observacao"
              @change="change = true"
          />
        </v-col>
      </template>
    </FiltroMobile>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data: () => {
    return {
      change: false,
      empresaId: '',
      producaoId: '',
      unidadeNegocio: 0,
      situacao: 'Z',
      dataInicial: '',
      dataFinal: '',
      codigoOS: '',
      servico: '',
      fornecedor: '',
      digitador: '',
      contratante: '',
      observacao: '',
      unidadeNegocioOptionsFilter: [
        { id: 0, nome: 'Todas' },
        { id: 4, nome: 'Administrativa' },
        { id: 2, nome: 'Cana-de-Açucar' },
        { id: 3, nome: 'Comercialização' },
        { id: 1, nome: 'Grama' },
        { id: 5, nome: 'Grãos' },
      ],
      situacaoOptionsFilter: [
        { value: 'Z', text: 'Todas' },
        { value: 'E', text: 'Executada' },
        { value: 'R', text: 'Reprovada' },
        { value: 'V1', text: 'Valid.Niv1' },
        { value: 'V2', text: 'Valid.Niv2' },
      ],
    }
  },
  mounted () {
    this.empresaId = this.value?.empresaId
    this.producaoId = this.value?.producaoId
    this.unidadeNegocio = this.value?.unidadeNegocio
    this.situacao = this.value?.situacao
    this.dataInicial = this.value?.dataInicial
    this.dataFinal = this.value?.dataFinal
    this.codigoOS = this.value?.codigoOS
    this.servico = this.value?.servico
    this.fornecedor = this.value?.fornecedor
    this.contratante = this.value?.contratante
    this.digitador = this.value?.digitador
    this.observacao = this.value?.observacao
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  watch: {
    'value.dataInicial' (newValue) {
      this.dataInicial = newValue
    },
    'value.dataFinal' (newValue) {
      this.dataInicial = this.value?.dataInicial
      this.dataFinal = newValue
    },
  },
  methods: {
    seachFilter () {
      this.model = {
        dataInicial: this.dataInicial,
        dataFinal: this.dataFinal,
        empresaId: this.empresaId,
        producaoId: this.producaoId,
        unidadeNegocio: this.unidadeNegocio,
        situacao: this.situacao,
        codigoOS: this.codigoOS,
        servico: this.servico,
        fornecedor: this.fornecedor,
        contratante: this.contratante,
        digitador: this.digitador,
        observacao: this.observacao,
      }
    },
  },
}
</script>
